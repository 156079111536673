<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="5">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="icon-menu mr-2"></i>
              <strong>
                Infos sur la parcelle
                <span v-if="parcel">
                  ({{parcel.name}})
                </span>
              </strong>
            </div>
            <b-form>
              <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
              
              <c-input type="image" v-model="picture" default="DEFAULT_PARCEL_PICTURE"
                       container-class="v-step-photo"></c-input>
              
              <c-input container-class="mb-3 required-input v-step-name" label="Nom de la parcelle"
                       placeholder="Ex: Parcelle de Cacao"
                       v-model="name" :state="nameState">
                Veuillez saisir un nom
              </c-input>
              
              <c-input container-class="mb-3 mt-3 area" label="Aire de la parcelle (ha)"
                       placeholder="Calculée automatiquement"
                       v-model="area" :state="areaState" :disabled="true" id="area">
                Veuillez delimiter votre parcelle
              </c-input>
              
              <c-input container-class="mb-3" type="textarea" label="Commentaire"
                       placeholder="Entrez un commentaire..." v-model="comment">
              </c-input>
              
              <b-input-group class="mb-3" v-if="parcel">
                <b-button variant="link" class="px-0 text-danger" @click.prevent="deleteParcel">
                  Supprimer cette parcelle
                </b-button>
              </b-input-group>
            
            </b-form>
            
            <div id="btns" class="text-center">
              <button-spinner variant="success" type="submit" class="px-4" @click="onSubmit"
                              :fetching="fetchingCreateParcel" id="v-step-valid">
                {{submitBtnText}}
              </button-spinner>
              <b-button letiant="secondary" @click="onCancel" class="ml-2">
                Annuler
              </b-button>
            </div>
          </b-card>
        </b-col>
        
        <b-col cols="7">
          <b-card header-tag="header" footer-tag="footer" class="map-card">
            <div slot="header">
              <i class="icon-map mr-2"></i><strong> Délimitation</strong>
              <info-message>
                Delimitez la parcelle sur laquelle vous souhaitez cultiver.
                Pour delimiter la parcelle, Cliquez sur l'icone de dessin sur la carte. Ensuite vous cherchez les
                sommets
                de votre parcelle sur la carte et cliquez sur ceux-ci au fur et à mesure que vous les reperez.
              </info-message>
              <div class="card-header-actions">
                <b-link variant="primary" class="btn btn-primary" :href="csvTemplateUrl" download="parcelle.xlsx"
                        id="v-step-download_file">
                  <i class="fa fa-download"></i>
                  Fichier CSV exemple
                </b-link>
              </div>
            </div>
            
            <b-input-group class="mb-3" id="v-step-delimitation">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-file-excel-o"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-file
                :state="csvFileState"
                v-model="csvFile"
                placeholder="Délimitez à partir d'un fichier de coordonnées..."
                drop-placeholder="Déposez votre fichier ici..."
                @change="csvFileChanged"
              ></b-form-file>
              <b-form-invalid-feedback :state="csvFileState == null">
                Mauvais format du fichier
              </b-form-invalid-feedback>
            </b-input-group>
            
            <div id="map" :data-map="this.map" :data-vectorSource="this.vectorSource" :data-draw="this.drawInteraction"
                 :data-sketch="this.sketch" :data-graphicsLayer="this.graphicsLayer" :data-view="this.view">
            </div>
            <!--            @click="addDrawInteraction"-->
            <!--<button id="drawButton" title="Click pour dessiner" ref="drawButton"><i class="fa fa-area-chart"
                                                                                    style="font-size: 70%"></i>
            </button>-->
            <!-- <div id="drawDiv" class="draw-control ol-unselectable ol-control" ref="drawDiv"></div>

             <button id="deleteFeatureButton" title="Supprimer le dessin" ref="deleteFeatureButton"><i
               class="fa fa-trash" style="font-size: 70%"></i></button>

             <div class="delete-control ol-control ol-unselectable" ref="deleteFeatureDiv">

             </div>-->
          
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {loadModules, loadCss} from "esri-loader";
  import Feature from 'ol/Feature.js';
  import {getArea} from 'ol/sphere.js';
  import View from 'ol/View.js';
  import {Polygon} from 'ol/geom.js';
  import {Modify} from 'ol/interaction';
  import proj4 from 'proj4'
  
  import {Api, Regex, Toast, env, asset, str} from "../../../helpers/index";
  import {endGuide} from "../../../helpers";
  
  
  export default {
    name: "ExploitationCreateParcel",
    title: "PIA - Nouvelle parcelle",
    data() {
      return {
        picture: null,
        name: '',
        cultureId: null,
        comment: '',
        submitted: false,
        area: '',
        map: null,
        vectorSource: null,
        drawInteraction: null,
        error: null,
        shapeType: 'Polygon',
        cultureOptions: [
          {value: "null", text: 'Aucune culture', selected: true, disabled: true}
        ],
        fetchingCreateParcel: false,
        parcel: null,
        fetchingParcel: false,
        csvFile: null,
        csvFileContent: null,
        planarArea: null,
        graphicsLayer: null,
        MapView: null,
        view: null,
        sketch: null,
        Graphic: null,
        Sketch: null,
        Fullscreen: null,
        Polygon: null,
        stroke: {
          color: [255, 0, 0],
          width: 1
        },
        fillColor: [255, 0, 0, 0.3],
        
        myOptions: {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Quittez',
            buttonPrevious: 'Précédent',
            buttonNext: 'Suivant',
            buttonStop: 'Terminé'
          }
        },
      }
    },
    created() {
      this.getCultures();
      
      Toast.guide_msg(
        "Vous devez créer une parcelle pour le lancement de votre production! Remplissez tous les champs pour de création",
        null,
        null,
        null,
        null,
        1,
        [this.exploitationId],
        null,
        this.openWarehouse,
        null,
        null,
        endGuide
      )
      
    },
    async mounted() {
      const edit = this.$route.query.edit
      if (edit && Regex.isPositiveNumber(edit)) {
        this.getParcel(edit)
      } else {
        await this.loadFirst();
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      _picture() {
        return this.picture ? this.picture : env('DEFAULT_PARCEL_PICTURE')
      },
      csvTemplateUrl() {
        return env('PARCEL_CSV_TEMPLATE')
      },
      submitBtnText() {
        return this.parcel ? 'Enregistrer' : 'Valider'
      },
      nameState() {
        return !this.submitted || this.name.trim().length >= 3 ? null : false
      },
      areaState() {
        return !this.submitted || Regex.isNumber(this.area.replace(/ha/gi, '').trim()) ? null : false
      },
      csvFileState() {
        return this.csvFile == null || this.isValidCsv() ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    methods: {
      asset,
      async loadMod(modules) {
        try {
          let parts;
          let modulesToSend = {};
          for (let module of modules) {
            parts = module.split("/");
            let [tmp] = await loadModules([module]);
            modulesToSend[parts[parts.length - 1]] = tmp;
          }
          
          return modulesToSend;
        } catch (error) {
          console.error(error);
        }
      },
      isValidCsv() {
        let coordinates = null
        try {
          coordinates = this.csvFileContent
            .split(/[\s]/)
            .filter((item, index) => index > 0 && item.trim() !== '')
            .map(item => item.split(/[;,]/))
            .map(item => item.map(item => parseFloat(item)))
          
          for (let i = 0; i < coordinates.length; i++) {
            if (
              coordinates[i].length !== 2
              || coordinates[i][0] < -90
              || coordinates[i][0] > 90
              || coordinates[i][1] < -180
              || coordinates[i][1] > 180
            )
              return null
          }
        } catch (e) {
          return null
        }
        
        return coordinates
      },
      renderMapFromCsv() {
        let coordinates = this.isValidCsv()
        
        if (!coordinates)
          return
        
        if (this.vectorSource.getFeatures().length)
          this.deletePolygon()
        
        coordinates = [
          coordinates.map(item => proj4('EPSG:4326', 'EPSG:3857', item))
        ]
        
        let polygon = new Polygon(coordinates);
        let feature = new Feature(polygon)
        this.vectorSource.addFeature(feature);
        
        this.formatPolygonArea(polygon)
        
        feature.getGeometry().on('change', this.onChangeArea)
        
        this.map.setView(new View({
          center: feature.getGeometry().getInteriorPoint().getCoordinates(),
          zoom: 15
        }))
        
        this.map.addInteraction(new Modify({
          source: this.vectorSource
        }))
      },
      csvFileChanged(e) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length)
          return;
        
        const reader = new FileReader();
        const vm = this;
        
        reader.onload = (e) => {
          vm.csvFileContent = e.target.result;
          this.renderMapFromCsv()
        };
        reader.readAsText(files[0]);
      },
      onPictureChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        
        const reader = new FileReader();
        const vm = this;
        
        reader.onload = (e) => {
          vm.picture = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      },
      formatPolygonArea(polygon) {
        this.area = this.planarArea(polygon, 'hectares').toFixed(5) + ' ha';
      },
      onChangeArea(event) {
        let geom = event.target;
        this.formatPolygonArea(geom)
      },
      async loadMap() {
        loadCss()
        let modules = await this.loadMod([
          "esri/WebMap",
          "esri/views/MapView",
          "esri/layers/GraphicsLayer",
          "esri/widgets/Sketch",
          "esri/widgets/Fullscreen",
          "esri/geometry/geometryEngine",
          "esri/Graphic",
          "esri/geometry/projection",
          "esri/geometry/SpatialReference",
          "esri/geometry/Polygon"
        ]);
        
        this.planarArea = modules.geometryEngine.planarArea
        this.graphicsLayer = new modules.GraphicsLayer();
        this.MapView = modules.MapView
        this.Graphic = modules.Graphic
        this.Sketch = modules.Sketch
        this.Fullscreen = modules.Fullscreen
        this.Polygon = modules.Polygon
        
        this.map = new modules.WebMap({
          portalItem: {
            id: "d802f08316e84c6592ef681c50178f17" //imagery with label and transportation
            // id: "86265e5a4bbb4187a59719cf134e0018" // world imagery
          },
          layers: [this.graphicsLayer]
        });
      },
      addEventOnFeature() {
        this.sketch = new this.Sketch({
          view: this.view,
          availableCreateTools: ["polygon"],
          layer: this.graphicsLayer
        });
        
        const _this = this
        
        this.sketch.on("create", event => {
          switch (event.state) {
            case'start':
              _this.graphicsLayer.removeAll()
              console.log('Nous sommes dans le start')
              break
            case 'active':
              _this.formatPolygonArea(event.graphic.geometry)
              break
            case 'complete':
              _this.formatPolygonArea(event.graphic.geometry)
              console.log('Le complete: ', _this.area)
              break
          }
        });
        
        this.sketch.on("update", event => {
          _this.formatPolygonArea(event.graphics[0].geometry)
        });
        
        let polygonSymbol = this.sketch.viewModel.polygonSymbol;
        polygonSymbol.color = this.fillColor;
        polygonSymbol.outline = this.stroke;
        
        let fullscreen = new this.Fullscreen({
          view: this.view
        });
        
        this.view.ui.add(this.sketch, "top-right");
        this.view.ui.add(fullscreen, "top-left");
      },
      async loadFirst() {
        await this.loadMap()
        
        this.view = new this.MapView({
          container: "map",
          map: this.map,
          center: [12.354722, 7.369722],
          zoom: 6
        });
        
        this.addEventOnFeature()
      },
      getCultures() {
        Api.get('/exploitation/culture/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.cultureOptions = res.data.data.map(culture => ({
                value: culture.id,
                text: culture.name
              }))
            } else {
              this.error = res.data.error
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            // this.fetchingCultureList = false
          })
      },
      async renderMap() {
        await this.loadMap()
        
        let polygon = new this.Polygon({
          spatialReference: {
            wkid: this.parcel.feature.wkid
          },
          rings: this.parcel.feature.coordinates
        })
        
        this.view = new this.MapView({
          container: "map",
          map: this.map,
          center: this.parcel.feature.center,
          zoom: this.parcel.feature.zoom
        });
        
        this.addEventOnFeature()
        
        let feature = new this.Graphic({
          geometry: polygon,
          symbol: {
            type: "simple-fill",
            color: this.fillColor /*[227, 139, 79, 0.8]*/,  // orange, opacity 80%
            outline: this.stroke
          },
          attributes: {
            name: this.parcel.name,
            comment: this.parcel.comment,
            area: this.parcel.area + " ha"
          },
          popupTemplate: {
            title: "Informations sur la Parcelle",
            content: [{
              type: "fields",
              fieldInfos: [{
                fieldName: "name",
                label: "Nom"
              }, {
                fieldName: "area",
                label: "Aire"
              }, {
                fieldName: "comment",
                label: "Commentaire"
              }]
            }]
          }
        })
        
        this.graphicsLayer.add(feature);
      },
      getParcel(parcelId) {
        this.fetchingParcel = true
        Api.get('/exploitation/parcel', {
          exploitationId: this.exploitationId,
          parcelId
        })
          .then(async res => {
            if (res.data.status === 'success' && res.data.data) {
              this.parcel = res.data.data
              
              this.picture = this.asset(this.parcel.picture)
              this.name = this.parcel.name
              this.area = this.parcel.area + ' ha'
              this.cultureId = this.parcel.cultureId
              this.comment = this.parcel.comment
              
              await this.renderMap()
              
            } else {
              this.error = res.data.error
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            console.log('======================== Erreur: ', error)
            
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingParcel = false
          })
      },
      drawStart_End() {
        let listener;
        let modify;
        let sketch;
        
        let formatPolygonArea = function (polygon) {
          let area = getArea(polygon);
          let output;
          if (area > 10000) {
            // (Math.round(area / 1000000 * 100) / 100)
            output = +((area / 1000000) * 100).toFixed(4) +
              ' ' + 'ha';
          } else {
            // (Math.round(area * 100) / 100)
            output = (area / 10000).toFixed(4) +
              ' ' + 'ha';
          }
          return output;
        };
        
        let circleArea = (radius) => {
          return ((Math.PI * radius * radius) / 10000).toFixed(4) +
            ' ' + 'ha';
        }
        const _this = this
        
        this.drawInteraction.on('drawstart',
          function (evt) {
            sketch = evt.feature;
            listener = sketch.getGeometry().on('change', _this.onChangeArea)
          });
        
        this.drawInteraction.on('drawend',
          function () {
            modify = new Modify({
              source: _this.vectorSource
            });
            _this.map.removeInteraction(_this.drawInteraction);
            _this.map.addInteraction(modify);
          });
      },
      addDrawInteraction() {
        if (!this.drawInteraction.getActive()) {
          this.drawInteraction.setActive(true);
          this.map.addInteraction(this.drawInteraction);
          this.drawStart_End();
        }
      },
      deletePolygon() {
        this.vectorSource.clear();
        this.area = '';
        this.drawInteraction.setActive(false)
        this.csvFile = null
        
        this.addDrawInteraction()
      },
      redirect() {
        this.$router.push({
          name: 'ExploitationParcels',
          params: {id: this.exploitationId}
        })
      },
      valid() {
        return this.name.trim().length >= 3 &&
          Regex.isNullOrPositiveNumber(this.cultureId) &&
          Regex.isNumber(this.area.replace(/ha/gi, '').trim())
      },
      onSubmit() {
        return this.parcel ? this.updateParcel() : this.createParcel()
      },
      onCancel() {
        return this.redirect()
      },
      createParcel() {
        this.submitted = true;
        if (!this.valid()) return
        
        this.fetchingCreateParcel = true
        
        let feature = {
          type: this.graphicsLayer.graphics.items[0].geometry.type,
          // this.vectorSource.getFeatures()[0].getGeometry().getType(),
          center: [
            this.graphicsLayer.graphics.items[0].geometry.centroid.longitude,
            this.graphicsLayer.graphics.items[0].geometry.centroid.latitude
          ],
          // this.vectorSource.getFeatures()[0].getGeometry().getInteriorPoint().getCoordinates(),
          zoom: this.view.zoom,
          wkid: this.graphicsLayer.graphics.items[0].geometry.spatialReference.wkid,
          coordinates: this.graphicsLayer.graphics.items[0].geometry.rings
        };
        
        Api.post('/exploitation/parcel/create', {
          picture: this.picture,
          name: this.name,
          cultureId: this.cultureId,
          exploitationId: this.exploitationId,
          comment: this.comment,
          area: parseFloat(this.area.replace('ha', '').trim()),
          feature: feature
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Parcelle créée avec succès !')
              this.redirect()
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateParcel = false
          })
      },
      updateParcel() {
        this.submitted = true;
        if (!this.valid()) return
        
        this.fetchingCreateParcel = true
        
        let feature = {
          type: this.graphicsLayer.graphics.items[0].geometry.type,
          // this.vectorSource.getFeatures()[0].getGeometry().getType(),
          center: [
            this.graphicsLayer.graphics.items[0].geometry.centroid.longitude,
            this.graphicsLayer.graphics.items[0].geometry.centroid.latitude
          ],
          // this.vectorSource.getFeatures()[0].getGeometry().getInteriorPoint().getCoordinates(),
          zoom: this.view.zoom,
          wkid: this.graphicsLayer.graphics.items[0].geometry.spatialReference.wkid,
          coordinates: this.graphicsLayer.graphics.items[0].geometry.rings
        };
        
        Api.post('/exploitation/parcel/update', {
          parcelId: this.parcel.id,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
          name: this.name,
          cultureId: this.cultureId,
          exploitationId: this.exploitationId,
          comment: this.comment,
          area: parseFloat(this.area.replace('ha', '').trim()),
          feature: feature
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Parcelle mise à jour avec succès !')
              this.redirect()
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateParcel = false
          })
      },
      deleteParcel() {
        const res = confirm(`Etes-vous sûr de vouloir supprimer cette parcelle ?`)
        if (res) {
          Api.post('/exploitation/parcel/delete', {
            exploitationId: this.exploitationId,
            parcelId: this.parcel.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                Toast.success('Parcelle supprimée avec succès !')
                this.redirect()
              } else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              this.fetchingCreateParcel = false
            })
        }
      },
      nextComposantToVisit() {
        this.$router.push('/exploitation/' + this.exploitationId + '/parcels/cultures/list')
      },
      openWarehouse(id) {
        this.$router.push('/exploitation/'+this.exploitationId+'/management/warehouses/create')
      },
    }
  }
</script>

<style scoped lang="stylus">
  @import "../../../../node_modules/ol/ol.css"
  #map{
    width: 100%;
    height: 480px;
  }
  
  .draw-control{
    top: 50px;
    right: .5em;
  }
  
  .delete-control{
    top: 76px;
    right: .5em;
  }
  
  .map-card{
    /*min-height: 600px;*/
  }
</style>
